<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Fiche lead #{{lead.id}}</h3>
        </div>
        <div class="card-toolbar">
          <a class="btn btn-icon btn-sm btn-success mr-1" @click="save">
            <i class="icon-md fas fa-save"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <b-form>
          <AppForm v-model="lead"></AppForm>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AppForm from "@/view/layout/custom/Form.vue";

export default {
  data(){
    return {
      lead:{
        prenom: null,
        nom: null,
        telephone: null,
        mobile: null,
        email: null,
        user_id: null,
        status_id: null,
        source_id: 1,
        date_create: null,
        date_update: null,
        status: null,
        commentaire: null,
        id: null,
        logs: []
      },
      parametrage:{
        role:[],
        status:[]
      },
    };
  },
  components: {
    AppForm
  },
  watch: {
  },
  methods: {
    save() {
      this.$store.back.ajax('/lead/create', this.lead, (res) => {
        if(res.status === true){
          this.lead = res.data;
          this.$router.push({path:`/lead/detail/${this.lead.id}`});
        }
      });
    }
  },
  computed: {

  },
  beforeMount(){
    let params = {
      modules: ["UserRole"]
    };
    this.$store.back.ajax('/parametrage/all', params, (res) => {
      if(res.status === true){
        this.parametrage.role = res.data['UserRole'];
        this.parametrage.status = res.data['status'];
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
    console.log(this.$store.back.history)
  }
};
</script>
